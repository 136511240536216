import { render, staticRenderFns } from "./ArticleVideo.vue?vue&type=template&id=e9945c26"
import script from "./ArticleVideo.vue?vue&type=script&lang=ts"
export * from "./ArticleVideo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtStoryblokImage: require('/home/runner/work/es-cms-articles/es-cms-articles/components/NuxtStoryblokImage.vue').default})
