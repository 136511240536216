
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { EsVideo } from '@energysage/es-vue-base';
import type { ArticleVideoBlokInterface } from '~/types/articlePage';

export default defineComponent({
    name: 'ArticleVideo',
    components: {
        EsVideo,
    },
    props: {
        blok: {
            type: Object as PropType<ArticleVideoBlokInterface>,
            required: true,
        },
    },
});
